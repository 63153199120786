<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-5">
          <router-link to="/" class="d-flex align-center">
            <v-img class="mb-6" max-width="150" contain :src="appLogo"></v-img>
          </router-link>
        </v-card-title>

        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            {{ $t('page.create_password.create_password') }}
          </p>
          <p class="mb-2">{{ $t('page.create_password.description') }}</p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form @submit.prevent>
            <v-text-field :value="email" disabled outlined label="Email" hide-details="auto" class="mb-6" />
            <v-text-field
              v-model="password.model"
              :disabled="loading"
              :label="$t('label.password')"
              :error="!!repeat_password.error"
              type="password"
              outlined
              hide-details="auto"
              counter=""
              :hint="$t('common.min_x_characters', { x: 8 })"
            />
            <v-text-field
              v-model="repeat_password.model"
              :disabled="loading"
              :label="$t('label.repeat_password')"
              :error-messages="$t(repeat_password.error, { field: $t('label.password'), x: 8 })"
              type="password"
              outlined
              hide-details="auto"
              class="mb-6"
              counter
              :hint="$t('common.min_x_characters', { x: 8 })"
            />

            <v-btn
              @click="createPasswordOnClick"
              type="submit"
              block
              color="primary"
              :disabled="loading"
              :loading="loading"
            >
              {{ $t('page.create_password.change_password') }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
import themeConfig from '@themeConfig'

export default {
  name: 'Registration',
  beforeMount() {
    this.email = new URLSearchParams(window.location.search).get('email')
  },
  data: () => ({
    loading: false,

    email: '',
    password: {
      model: '',
    },
    repeat_password: {
      model: '',
      error: '',
    },

    appName: themeConfig.app.name,
    appLogo: themeConfig.app.logo,
    arrowLeftIcon: mdiChevronLeft,
  }),
  methods: {
    createPasswordOnClick: function () {
      this.loading = true

      if (this.password.model !== this.repeat_password.model) {
        this.repeat_password.error = 'page.create_password.passwords_dont_match'
        this.loading = false
        return
      }

      const reg_token = new URLSearchParams(window.location.search).get('reg_token')

      this.$http
        .post('register', {
          email: this.email,
          password: this.password.model,
          reg_token: reg_token,
        })
        .then(() => {
          this.$router.push('/login')
        })
        .catch(err => {
          const errors = err.response.data.error
          if (errors) {
            this.repeat_password.error = errors['password'][0]
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>

